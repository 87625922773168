import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { ConstantFontColor } from "@constant/ConstantFontColor";

interface CommonButtonProps {
  title: string;
  onClick?: () => void;
  fontFamily?: string;
  fontWeight?: string | number;
  backgroundColor?: string;
  arrowIcon?: boolean;
  margin?: number | string;
  radius?: number;
  width?: number | string;
  fontSize?: number | string;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  title,
  onClick,
  fontFamily,
  fontWeight,
  backgroundColor,
  arrowIcon,
  margin,
  radius,
  width,
  fontSize,
}) => {
  return (
    <div>
      {/* <button
        className="group flex items-center px-5 py-4 cursor-pointer justify-center bg-blue-600 rounded-xl"
        onClick={onClick}
        style={{
          fontFamily: "Rubik",
          margin,
          borderRadius: radius,
          width,
        }}
      >
        <span
          className="mr-3 text-white text-[13px]"
          style={{
            fontFamily,
            fontWeight,
            fontSize: fontSize ?? "13px",
          }}
        >
          {title ?? ""}
        </span>
        {arrowIcon && (
          <FaArrowRightLong className="text-lg text-white group-hover:animate-wobble-hor" />
        )}
      </button> */}

      <button
        className={`group relative flex items-center xl:py-4 lg:py-3 xl:px-8 lg:px-5 sm:px-5 sm:py-4 px-5 py-4 cursor-pointer justify-center overflow-hidden  `}
        onClick={onClick}
        style={{
          backgroundColor: backgroundColor,
          margin: margin,
          borderRadius: radius,
          fontFamily: constantFontFamily.rubik,
          width: width,
        }}
      >
        {/* <span className="absolute inset-0 bg-[#0D242E] transition-transform duration-300 ease-in-out transform translate-y-full group-hover:translate-y-0"></span> */}
        <span
          className="mr-3 text-[13px] "
          style={{
            fontFamily: fontFamily,
            fontWeight: fontWeight,
            color: ConstantFontColor.white,
            fontSize: fontSize ?? "13px",
          }}
        >
          {title ?? ""}
        </span>
        {arrowIcon && (
          <FaArrowRightLong
            style={{ color: ConstantFontColor.white }}
            className="text-lg  group-hover:animate-wobble-hor"
          />
        )}
      </button>
    </div>
  );
};

export default CommonButton;
