import React, { useState } from "react";
import { imageData } from "@constant/images/Images";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import Hamburger from "hamburger-react";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import SideBar from "./sideBar/SideBar";
import { headerTab } from "@utils/DummyArray";
import CommonButton from "@componets/button/CommonButton";

interface HeaderProps {
  selectedTabItem?: any; // Replace 'any' with the appropriate type if known
}

const Header: React.FC<HeaderProps> = ({ selectedTabItem }) => {
  const navigate = useNavigate();

  const [isOpenSideBar, setIsOpenSideBar] = useState<boolean>(false);
  const handleCloseSideBar = () => setIsOpenSideBar(false);

  const { innerWidth } = window;

  const widthSet = () => {
    if (innerWidth >= 1440) {
      return 230;
    } else if (innerWidth >= 1024) {
      return 160;
    } else if (innerWidth >= 768) {
      return 150;
    } else if (innerWidth >= 425) {
      return 130;
    } else if (innerWidth >= 375) {
      return 110;
    } else if (innerWidth >= 320) {
      return 100;
    } else {
      return 180;
    }
  };

  const headerTabClick = (index: number) => {
    if (index === 0) {
      navigate("/");
    } else if (index === 1) {
      navigate("/aboutUs");
    } else if (index === 2) {
      navigate("/services");
    } else if (index === 2) {
      navigate("/projects");
    } else if (index === 3) {
      navigate("/contactUs");
    }
  };

  return (
    <div className="flex items-center justify-between 2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5  py-2 sm:py-5 md:py-3 lg:py-[22px]">
      <div className="flex items-center ">
        <div
          className="xl:mr-10 lg:mr-5 2xl:w-auto 2xl:h-auto xl:w-36 lg:w-2/4 md:w-2/3 sm:w-2/4 w-2/4 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src={imageData.hmSolarLogo}
            loading="lazy"
            alt=""
            className="w-full h-full"
          />
        </div>
        <div className="lg:flex sm:hidden hidden">
          {headerTab.map((headerTitle, index) => {
            const isActive = selectedTabItem === headerTitle.title;
            return (
              <div
                className="flex xl:mx-7 lg:mx-5"
                onClick={() => {
                  headerTabClick(index);
                }}
              >
                <div className="relative group">
                  <span
                    className={` xl:text-sm md:text-xs  cursor-pointer transition-all duration-300 ease-in-out `}
                    style={{
                      color:
                        selectedTabItem === headerTitle.title
                          ? ConstantFontColor.buttonColor
                          : ConstantFontColor.commanColor,
                      fontFamily: constantFontFamily.rubik,
                      fontWeight:
                        selectedTabItem === headerTitle.title ? 600 : 500,
                    }}
                  >
                    {headerTitle?.title}{" "}
                  </span>
                  {/* Hover underline */}
                  <span
                    className={`absolute bottom-0 left-0 h-[2px] transition-all duration-300 ease-in-out w-full scale-x-0 group-hover:scale-x-100 origin-center ${
                      isActive ? "scale-x-100" : ""
                    }`}
                    style={{ backgroundColor: ConstantFontColor.buttonColor }}
                  ></span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="lg:flex sm:hidden hidden xl:mx-5">
        <CommonButton
          arrowIcon
          title="REQUEST A QUOTE"
          fontWeight="600"
          backgroundColor={ConstantFontColor.buttonColor}
          width={widthSet()}
          onClick={() => {
            navigate("/requestQuote");
          }}
        />
      </div>
      <div className="flex lg:hidden">
        <Hamburger
          toggle={setIsOpenSideBar}
          toggled={isOpenSideBar}
          size={24}
        />
      </div>

      <Modal
        open={isOpenSideBar}
        onClose={handleCloseSideBar}
        className="flex justify-end items-start"
      >
        <div
          className={`h-full w-2/3 md:w-2/5 shadow-lg transition-transform duration-500 ease-in-out transform
      ${isOpenSideBar ? "translate-x-0" : "translate-x-full"}
    `}
        >
          <SideBar
            handleCloseSideBar={handleCloseSideBar}
            headerTab={headerTab}
            selectedTabItem={selectedTabItem}
            headerTabClick={headerTabClick}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Header;
