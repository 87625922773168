import Tittle from "@common/tittle/Tittle";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import ServiceCard from "@pages/home/energyServices/energyServicesCart/serviceCard/ServiceCard";
import { mainServicesData } from "@utils/DummyArray";
import { useInView } from "react-intersection-observer";

const ServiceDataMain = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  return (
    <div
      className="flex justify-center items-center 2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 py-10 flex-col"
      style={{ backgroundColor: ConstantFontColor.lightBackgroundColor }}
    >
      <Tittle
        colorTitle="SPECIALISE IN THE ENERGY SERVICE"
        title="Sustainable Energy Services"
        textAlign="center"
      />
      <div
        ref={ref}
        className="flex justify-center items-center flex-wrap mt-10  duration-500"
      >
        {mainServicesData.map((items, index) => (
          <div
            key={index}
            className={` ${
              inView
                ? "opacity-0 animate-fadeInUp transition-transform duration-500"
                : ""
            }`}
            style={{ animationDelay: `${index * 0.3}s` }}
          >
            <ServiceCard items={items} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceDataMain;
