import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { FaArrowRightLong } from "react-icons/fa6";

const ServiceCard = ({ items }: any) => {
  return (
    <div
      className="md:w-[320px] sm:w-60 w-64 m-3 rounded-xl"
      style={{ backgroundColor: ConstantFontColor.white }}
    >
      <div className="rounded-t-xl md:w-[320px] sm:w-60 w-64 h-36  md:h-[216px] sm:h-40">
        <img
          src={items?.img}
          loading="lazy"
          className="rounded-t-xl w-full h-full "
          alt=""
        />
      </div>
      <div className="flex flex-col py-4 px-5">
        <div className="sm:w-64 w-52">
          <span
            className="sm:text-base text-sm font-semibold text-left  line-clamp-1"
            style={{
              color: ConstantFontColor.commanColor,
              fontFamily: constantFontFamily.rubik,
            }}
          >
            {items?.title}
          </span>
          <span
            className="sm:text-sm text-xs font-normal text-left my-3  line-clamp-3"
            style={{
              color: ConstantFontColor.greyText,
              fontFamily: constantFontFamily.rubik,
            }}
          >
            {items?.desc}
          </span>
        </div>
        <div className="flex border-t-2 mt-2 justify-center">
          <span
            className="flex items-center pt-4 cursor-pointer"
            style={{
              color: ConstantFontColor.buttonColor,
              fontFamily: constantFontFamily.rubik,
            }}
          >
            Read More <FaArrowRightLong className="text-lg ml-2" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
