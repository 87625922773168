import Tittle from "@common/tittle/Tittle";
import ContactUsForm from "@common/contactUsForm/ContactUsForm";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { imageData } from "@constant/images/Images";
import { contactDetailsGetInTouch } from "@utils/DummyArray";

const GetInTouch = () => {
  return (
    <div className="2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 py-16 flex justify-between xl:flex-row sm:flex-col flex-col">
      <div className="sm:w-2/4 w-full flex flex-col items-start">
        <Tittle colorTitle="CONTACT US" title="Get In Touch" textAlign="left" />
        <span
          className="text-left py-5 text-sm  sm:text-base font-normal xl:w-2/4 sm:w-full w-full"
          style={{
            fontFamily: constantFontFamily.rubik,
            color: ConstantFontColor.lightGreyText,
          }}
        >
          Are you ready to take your first step towards a more sustainable and
          energy-efficient future?
        </span>
        {contactDetailsGetInTouch.map((item, index) => {
          return (
            <div
              key={index}
              className={`flex items-center sm:py-3 py-2  `}
              style={{
                cursor: index > 1 ? "default" : "pointer",
              }}
              onClick={() => {
                if (index === 0) {
                  window.location.href = `mailto:${item.name}`;
                } else if (index === 1) {
                  window.location.href = `tel:${item.name}`;
                }
              }}
            >
              <span className="mr-3">{item.icon}</span>
              <span
                className="text-sm sm:text-base font-normal hover:font-medium hover:transition-all duration-500"
                style={{
                  fontFamily: constantFontFamily.rubik,
                  color: ConstantFontColor.lightGreyText,
                }}
              >
                {item.name}
              </span>
            </div>
          );
        })}
        <div className="flex sm:space-x-8 sm:space-y-0 space-y-4 pt-5 xl:pb-0 pb-10 sm:flex-row flex-col">
          <img
            src={imageData.contactUs1}
            loading="lazy"
            alt=""
            className="2xl:w-auto 2xl:h-auto xl:w-60 xl:h-56 lg:w-60 md:w-56 w-full h-full"
          />
          <img
            src={imageData.contactUs2}
            loading="lazy"
            alt=""
            className="2xl:w-auto 2xl:h-auto xl:w-60 xl:h-56 lg:w-60 md:w-56 w-full h-full"
          />
        </div>
      </div>
      <ContactUsForm />
    </div>
  );
};

export default GetInTouch;
