import TopBar from "@componets/topBar/TopBar";
import Header from "@componets/header/Header";
import TittleBanner from "@componets/mainTittleBanner/TittleBanner";
import ClientsSay from "@common/clientSay/ClientsSay";
import Footer from "@componets/footer/Footer";
import ServiceDataMain from "./serviceDataMain/ServiceDataMain";

const Services = () => {
  return (
    <div>
      <TopBar />
      <Header selectedTabItem={"SERVICES"} />
      <TittleBanner mainTitle="Services" subTitle="Services" />
      <ServiceDataMain />
      <ClientsSay />
      <Footer greenBanner />
    </div>
  );
};

export default Services;
