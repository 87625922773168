import TopBar from "@componets/topBar/TopBar";
import Header from "@componets/header/Header";
import TittleBanner from "@componets/mainTittleBanner/TittleBanner";
import ClientsSay from "@common/clientSay/ClientsSay";
import Footer from "@componets/footer/Footer";
import Introduce from "./introduce/Introduce";
import OurPartner from "@common/ourPartner/OurPartner";
import HowWeStarted from "./howWeStarted/HowWeStarted";
import MainEnergyServices from "@home/energyServices/MainEnergyServices";
import Experience from "@home/experience/Experience";

const AboutUs = () => {
  return (
    <div>
      <TopBar />
      <Header selectedTabItem={"ABOUT"} />
      <TittleBanner mainTitle="About US" subTitle="About Us" />
      <Introduce />
      <MainEnergyServices />
      <HowWeStarted />
      <Experience
        boxShadow={{ boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)" }}
      />
      <OurPartner />
      <ClientsSay />
      <Footer greenBanner />
    </div>
  );
};

export default AboutUs;
